import axios from 'axios';
import {UserAuthorizedLightModel} from './model/UserAuthorizedLightModel';
import {authContext} from "./Auth";
import i18n from "i18next";
import {LayersPreferences} from "./model/LayersPreferences";
import {UserPreferences} from "./model/UserPreferences";
import {strings} from "./strings";
import {objectTypesFilterId} from "./components/VectuelMap/Filters/FilterHelper";

export class AppService {

    public static userAuthorized: UserAuthorizedLightModel

    public static isUserExternal = () => AppService.userAuthorized?.profiles?.includes('EXT');

    public static loadUser = async (): Promise<UserAuthorizedLightModel> => {
        return await AppService.loadUserWithPreferences(true);
    }

    private static getUserAuthorized = async (login: string): Promise<UserAuthorizedLightModel> => {
        const response = await axios.get(`api/user/get-user-authorized?login=${login}`);
        AppService.userAuthorized = response.data;
        return response.data;
    }

    private static setDefaultPreferences = async () => {
        const lng = sessionStorage.getItem('user-language-selection') ?? i18n.language ?? "fr-fr";
        const filters: string[] = JSON.parse(localStorage.getItem("filters.displayed")) ?? [];

        const opacityConfig: LayersPreferences[] = Object.entries({...localStorage})
            .filter(([k, v]) => k.startsWith('layers.opacity.'))
            .map(([k, v]) => ({
                layerName: k.substring('layers.opacity.'.length),
                opacity: Number(v),
                active: false,
                order: -1 // sera mis par selectedLayers
            }));

        const selectedLayers: string[] = JSON.parse(localStorage.getItem("selectedLayers")) ?? [];
        const layersConfig: LayersPreferences[] = opacityConfig;
        selectedLayers.forEach((layer,index) => {
            let layerConfig = layersConfig.find(x => x.layerName == layer);
            if (!layerConfig) {
                layerConfig = {
                    layerName: layer,
                    opacity: 0.5,
                    active: true,
                    order: index
                };
                layersConfig.push(layerConfig);
            }else{
                layerConfig.active = true;
                layerConfig.order = index;
            }
        });
        const defaultPreferences: UserPreferences = {
            language: lng,
            activeFilters: filters,
            layers: layersConfig,
            selectedVectuelPoints: [objectTypesFilterId.ExploitationUnit],
            selectedPointsCategories: []
        }

        await AppService.SetDefaultPreferences(defaultPreferences);
    }

    private static loadUserWithPreferences = async (setDefault: boolean = false): Promise<UserAuthorizedLightModel> => {
        const user = await AppService.getUserAuthorized(authContext().getCachedUser().userName);
        if (!user.preferences) {
            if (!setDefault) {
                console.error(user);
                throw new Error("Cannot get user preferences");
            }

            await AppService.setDefaultPreferences();
            return await AppService.loadUserWithPreferences();
        } else {
            await i18n.changeLanguage(user.preferences.language);
            strings.setLanguage(user.preferences.language);
        }
        return user;
    }

    private static SetDefaultPreferences = async (preferences: UserPreferences) => {
        const response = await axios.post('api/user/set-default-preferences', preferences);
        return response.data;
    }
}